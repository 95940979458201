.RecentActivity h3{
    color: #000000;
    font-size: 22px;
    font-weight: 600;
    font-family: 'ralewayReg';
}
.RecentActivity{
    padding: 10px 30px;
}
.Track{
    padding-top: 25px;
}
.Circle{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 5px solid #8ECBF3;
    display: flex;
   
}
.Circle span{
    margin-left: 20px;
   
}
.vl {
    border-left: 3px solid #8ECBF3;
    height: 40px;
    margin-left: 8px;
    margin-top: -29px;
   
  }
  .Time{
    color: #000000;
    font-size: 14px;
    line-height: 14.06px;
    font-family: 'gilroyMed';
    margin-top: 5px;
    font-weight: 600;
    margin-left: 8px;

  }
  .StudentBox{
    width: 100%;
    height: 50px;
   background-color: #FFFFFF;
    border: 0.5px solid #F5F5F5;
   border-radius: 4px;
  
  }
  .StudentBox img{
    margin-top: 8px;
    margin-left: 15px;
    
   
  }
  .Name{
    color: #000000;
    font-size: 14px;
    line-height: 16.41px;
    font-family: 'gilroyMed';
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
   

  }
  .BorderRight{
    border-right: 1px solid #CBCBCB;
    height: 30px;
    margin-top: 10px;

  }
  .location{
    color: #000000;
    font-size: 14px;
    font-family: 'gilroyReg';
    margin-top: 13px;
    margin-left: 10px;
  }
  .Align{
    display: flex;
    justify-content: space-between;
  }
  .StatusDisplay{
    color: #0D77BD;
    font-size: 14px;
    font-family: 'gilroyReg';
    
  }
  .Status{
    padding-top: 10px;
    padding-right: 15px;
  }
  .OnBoard{
    color: #000000;
    font-size: 14px;
    line-height: 16.41px;
    margin-right: 15px;
    margin-top: 20px;
  }