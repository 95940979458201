.Card{
    width: 100%;
    height: 95px;
    border-radius: 4px;
    box-shadow:  0px -2px 20px rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    margin-top: 40px;

}
.SmallCard{
    width: 25%;
    height: 95px;
    background-color: #0D2641;
   
    align-items:flex-end;
}
.Cards{
    display: flex;
    justify-content: space-between;
}
.Cards h5{
    color: #000000;
    font-size: 18px;
    line-height: 20.53px;
    font-weight: 'gilroyMed';
   text-align: center;
   padding-top: 30px;
   padding-left: 25px;
    
    
}
.SmallCard h3{
    font-size: 28px;
    font-family: 'gilroyReg';
    color: #FFFFFF;
    text-align: center;
    padding-top: 30px;
}