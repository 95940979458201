
@font-face {
  font-family: 'gilroyReg';
  src: url(./assets/fonts/Gilroy-Regular.ttf);
}
@font-face {
  font-family: 'gilroyMed';
  src: url(./assets/fonts/Gilroy-Medium.ttf);
}
@font-face {
  font-family: 'ralewayReg';
  src: url(./assets/fonts/Raleway-Regular.ttf);
}
@font-face {
  font-family: 'gilroyBod';
  src: url(./assets/fonts/Gilroy-ExtraBold.ttf);
}
:where(.css-dev-only-do-not-override-1ij74fp).ant-layout .ant-layout-sider {
  background:  #0D2641 !important;
}

:where(.css-dev-only-do-not-override-1ij74fp).ant-input-affix-wrapper{
  padding: 8px 12px !important;
  border-radius: 16px !important;
  border: 0.5px solid #9A9A9A !important;
  background: #FFFFFF !important;
  
  
}
:where(.css-dev-only-do-not-override-1ij74fp).ant-layout .ant-layout-header {
  height: 100px !important;
}

:where(.css-dev-only-do-not-override-1ij74fp).ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: rgba(14, 115, 181, 0.25) !important;
  
}
:where(.css-dev-only-do-not-override-1ij74fp).ant-menu-light .ant-menu-item-selected {
  color: #33BBE0 !important;
}
.ant-menu-title-content:hover{
  color: #33BBE0 !important;
}
:where(.css-dev-only-do-not-override-1ij74fp).ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title {
  color: #FFFFFF !important;
}
input::-webkit-input-placeholder {
  font-size: 14px;
  color: #868686;
  font-family: 'ralewayReg';
}
:where(.css-dev-only-do-not-override-1ij74fp).ant-menu {
  font-size: 16px !important;
  font-family: 'gilroyReg' !important;
  color:#FFFFFF !important;
}
:where(.css-dev-only-do-not-override-1ij74fp).ant-menu .ant-menu-item .ant-menu-item-icon, :where(.css-dev-only-do-not-override-1ij74fp).ant-menu .ant-menu-submenu-title .ant-menu-item-icon, :where(.css-dev-only-do-not-override-1ij74fp).ant-menu .ant-menu-item .anticon:hover, :where(.css-dev-only-do-not-override-1ij74fp).ant-menu .ant-menu-submenu-title .anticon:hover {
  color:#FFFFFF !important;
}
:where(.css-dev-only-do-not-override-1ij74fp).ant-menu-inline .ant-menu-item:hover{
  color:#FFFFFF !important;
}
.ant-menu-submenu-title:hover{
  color:#FFFFFF !important;

}
.Dashboard{
  padding: 30px 20px;
}
.Head{
  font-size: 22px;
  font-weight: 600;
  font-family: 'ralewayReg';
  color:#0D2641;
  text-align: left;
}
.liveHead{
  font-size: 20px;
  color: #000000;
  font-family: 'gilroyMed';
  padding-top: 25px;
}
.marg-10{
  padding-bottom: 20px;
}
.enable{
  width: 80px;
  height: 25px;
  background-color: #C8EED3 !important;
 text-align: center;
  border-radius: 16px;
  border: 1px solid #60CE7E;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #017823;
  
  cursor: pointer;
}
.disable{
  width: 80px;
  height: 30px;
  background-color: #FEE4DC !important;
 text-align: center;
  border-radius: 16px;
  border: 1px solid #FA6638;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FA6638;
  cursor: pointer;

}
.statusDropDown{
  display: none;
 
  

}
.statusTd{
  cursor: pointer;
}
.statusTd:hover .statusDropDown{
  display: block;
  position: absolute;
  background-color: #FFFFFF;
 
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}
.modal-header {
  border-bottom: 0px solid #FFFFFF !important;
}
.modal-footer{
  border-top: 0px solid #FFFFFF !important;
}
.nopadmar{
  padding: 0px !important;
  margin: 0px;
  --bs-gutter-x: 0rem !important;
}
.mapTrack{
  display: flex;
  justify-content: space-between;
}
.mainPad{
  padding: 65px 25px
}
.Track{
  font-family: 'ralewayReg';
  font-weight: 600;
  color: #000000;
  font-size: 22px;
  line-height: 26px;

}
.trackBtn{
  width: 125px;
  height: 42px;
  background-color: #0D77BD;
  border-radius: 4px;
  color: #EBEBEB;
  font-size: 16px;
  font-family: 'gilroyMed';
  font-weight: 300;
  padding: 8px;

}
.viewStudent{
  color: #0D77BD;
  font-size: 14px;
  font-family: 'gilroyReg';
  cursor: pointer;
}
.liveTrack{
  color: #A4A4A4;
  font-size: 14px;
  font-family: 'gilroyReg';
  padding-top: 15px;
}
.line{
  width: 91%;
  height: 0;
  border: 1px solid #C4C4C4;
  margin: 3px;
  display:inline-block;
  margin-top: 25px;
}
.selectBox{
  padding-top: 25px;
  display: flex;
  justify-content: space-between;
}
.selectHead{
  color: #090909;
  font-size: 14px;
  font-family: 'gilroyReg';
  line-height: 16.41px;
}
:where(.css-dev-only-do-not-override-1ij74fp).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px !important;
  padding: 3px 16px !important;
  
  color: #EBEBEB !important;
    font-size: 16px !important;
    font-family: 'gilroyMed' !important;
    font-weight: 300 !important;
}

.filterBox{
  width: 90px;
  height: 40px;
  border: 1px solid #0D77BD;
  border-radius: 8px;
  color: #0D2641;
  font-family: 'gilroyReg';
  font-size: 16px;
  text-align: center;
  padding-top: 8px;
  cursor: pointer;

}
.pageout{
  padding-top: 20px;
  padding-right: 20px;
}
.exportBox{
  width: 90px;
  height: 40px;
  border: 1px solid #0D77BD;
  background-color: #0D77BD;
  color: #FFFFFF;
  font-size: 16px;
  padding-top: 5px;
  border-radius: 8px;
  margin-left: 15px;
  text-align: center;

}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px !important;
  z-index: 1;
}
.importBox:hover .dropdown-content{
  display: block;

}
.importBox{
  width: 120px;
  height: 50px;
  border: 1px solid #33BBE0;
  background-color: #33BBE0;
  color: #FFFFFF;
  font-size: 16px;
  padding-top: 10px;
  border-radius: 8px;
  margin-left: 15px;
  text-align: center;
  cursor: pointer;

}
.firstDrop{
  color: #33BBE0;
  font-size: 14px;
  font-family: 'gilroyReg';
  border-bottom: 1px solid #C2C2C2;
  padding-bottom: 15px;
  padding-top: 10px;
  text-align: left;
}
.secondDrop{
  color: #FF6060;
  font-size: 14px;
  font-family: 'gilroyReg';
  border-bottom: 1px solid #C2C2C2;
  padding-bottom: 15px;
  padding-top: 10px;
  text-align: left;
}
.exportBoxMas{
  width: 92px;
  height: 36px;
  cursor: pointer;
  border: 1px solid #0D77BD;
  background-color: #0D77BD;
  color: #FFFFFF;
  font-size: 16px;
  padding-top: 5px;
  border-radius: 8px;
  margin-left: 15px;
  text-align: center;

 

}
.table{
  width: 97%;
  margin-top: 25px;
 
}
.firstRow{
 height: 50px;
 background-color: #EBEBEB !important;
 

}
.ant-layout-content{
  background-color: #F5F5F5 !important;
}
/* .table>:not(caption)>*>* {
  padding: 0px !important;
} */
.firstRow th{
  padding-left: 15px;
  font-size: 14px;
  color: #000000;
  line-height: 16.41px;
  font-family: 'gilroyReg';

}
.busNo{
  color: #0D77BD;
  font-size: 14px;
  font-family: 'gilroyReg';
}
.rowItems{
  height: 70px;
  background: #f9f9f9;
  border-bottom: 2px solid  #F5F5F5;

}
.rowItems td{
  font-size: 14px;
  color: #000000;
  line-height: 16.41px;
  font-family: 'gilroyMed';
  padding-left: 15px;

}
.addStaff{
  width: 90px;
  height: 36px;
  
  border-radius: 8px;
  background: #0D77BD;
  color: #FFFFFF;
  font-size: 16px;
  font-family: 'gilroyReg';
  font-weight: 600;
  text-align: center;
  padding-top: 7px;
  cursor: pointer;
}
.btn-close{
  background: url(./assets/images/close.png) !important;
  background-repeat: no-repeat !important;
  opacity: 1 !important;
  margin-top: 10px !important;
}
.profile{
  padding-left: 10px;
}
.modalCont{
  padding-left: 5px;
  padding-top: 20px;
}
.modalCont h5{
  color: #9E9E9E;
  font-size: 14px;
}
.modalCont span{
  color: #1B1B1B;
  font-size: 16px;
  
}
.tripView{
  display: flex;
  justify-content: space-between;
}
.profile h5{
  font-size: 16px;
  color: #000000;
}
.h4, h4 {
  font-size: 18px !important;
  font-family: 'gilroyMed';
}
.date{
  float: right !important;
}
.btn-secondary{
  background-color: #D9D9D9 !important;
  border: 1px solid #D9D9D9 !important;
  color: #000000;
}
.btn-primary{
  background-color: #0D77BD !important;
}
.label{
  color: #0D2641;;
  font-size: 14px;
  font-family: 'gilroyMed';
  font-weight: 600;
  margin-top: 12px;
}
.inputText{
  width: 100%;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  height: 40px;
  color: #000000 !important;
  margin-top: 8px;
  background-color: #F9F9F9;
}
.modalbg{
  background-color: #F9F9F9 !important;
}
.submit{
  width: 140px;
  height: 45px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #0D77BD;
  color: #FFFFFF;
  text-align: center;
  font-family: 'gilroyReg';
  padding-top: 10px;
  margin-top: 30px;
  /* float: right; */
}
.loginBtn{
  width: 100%;
  height: 45px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #0D77BD;
  color: #FFFFFF;
  text-align: center;
  font-family: 'gilroyReg';
  padding-top: 10px;
  margin-top: 30px;
  float: right;
}
.forgot{
  display: flex;
  justify-content: space-between;
}
.CodeBox{
  width: 100% !important;
  height: 50px !important;
  border: 0.5px solid #8B8B8B !important;
  border-radius: 4px;
  font-size: 20px;
  padding: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 4px;
  outline: none;
  background-color:  #ffffff;
  color: #000000;
}
.pswdUpd{
  text-align: center;
  color: #0D2641;
  font-weight: 600;
font-size: 26px;
line-height: 23px;
font-family: 'ralewayReg';

}
.pswdUpdPara{
  font-weight: 400;
font-size: 16px;
line-height: 29px;
margin-top: 10px;
text-align: center;
text-transform: capitalize;

color: rgba(25, 36, 45, 0.6);

}
.OtpBsox{
  margin-left: 0px;
}
.resend{
  color: #0D2641;
  font-weight: 600;
  font-size: 16px;

}

.keepMe{
  padding-top: 18px;
  color:  #000000;
  font-size: 14px;
  font-family: 'ralewayReg';
}
.forgotLink{
  padding-top: 18px;
  color: #0D77BD;
  font-size: 14px;
  font-family: 'ralewayReg';

}
.modal-body {
  padding: 10px 20px 50px 20px !important;
}
.spaceBet{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}
.action{
  display: flex;
  justify-content: end;
  padding: 20px;
 
}
.idCard{
  font-size: 14px;
  color: #0D77BD !important;
  font-family: 'gilroyMed';
  
}
.idHead{
  color: #0D2641;
  font-size: 22px;
  font-family: 'gilroyMed';
  padding: 25px;
}
.goBack{
  width: 100%;
  height: 70px;
  background: #EDEEEF;
  font-size: 16px;
  line-height: 18.75px;
  color: #0D77BD;
  padding: 25px;
}
.studentCard{
  min-height: 250px;
  border-radius: 4px;
  background-color: #ECF5FB;
}
.studentCard h3{
  text-align: center;
  padding-top: 30px;
  padding-bottom: 50px;
}
.blackBox{
  width: 100%;
  height: 20px;
  background-color: #0D2641;
  border-radius: 4px;
  margin-top: 20px;
}
.profileId{
  margin-top: -40px;
}
.innerCard{
  width: 100%;
  min-height: 250px;
  background-color: #FFFFFF;
  border: 1px solid #F5F5F5;
  border-radius: 16px;
  padding-bottom: 20px;
  margin-bottom: 20px;
 
}
.ErrorMsg{
  color: rgba(255,0,0);
      text-align: left;
      font-size: 14px
}

.NoResults{
 width: 100%;
  text-align: center;
  font-size: 20px;
 
}
.issueVal{
  color: #0D77BD;
  font-size: 12px;
  font-family: 'gilroyReg';
}
.issue{
  color: #0D2641;
  font-size: 14px;
  font-family: 'gilroyReg';
  

}

.nameDet h5{
  padding: 10px 0px 0px 30px;
  font-size: 12px;
  color: #0D2641;
  font-family: 'gilroyMed';
}
.nameVal h5{
  padding: 10px 0px 0px 20px;
  font-size: 12px;
  color: #0D77BD;
  font-family: 'gilroyReg';

}
.nameValB{
  font-size: 16px;
  color: #0D2641;
  font-family: 'gilroyReg';
  padding: 10px 0px 0px 20px;
  font-weight:600;
}
.mapLabel{
  color: #FFFFFF !important;
  font-size: 9px !important;
  text-align: center !important;
  padding-top: 3px;
  padding-left: 5px !important;
  padding-right: 5px !important;
  min-width: 40px !important;
  height: 20px !important;
  background-color: #0D2641 !important;
  border-radius: 5px !important;
  margin-left: 50px !important;
  margin-top: -18px !important;
}
.map-container{
  width: 100%;
  height: 600px;
}
.selectText{
  width: 100%;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  height: 40px;
  margin-top: 8px;
  background-color: #E6E6E6;
  color: #656565;

}
:where(.css-dev-only-do-not-override-1ij74fp).ant-select-single.ant-select-open .ant-select-selection-item {
  color: #000000 !important;
}
:where(.css-dev-only-do-not-override-1ij74fp).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  color: #000000 !important;
}
:where(.css-dev-only-do-not-override-ixblex).ant-input-affix-wrapper {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.search{
  background-color: #0D77BD;
  border-top-right-radius:10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  padding: 3px 20px;
  color: #ffffff;
  cursor: pointer;
}
.changBtn{
  background-color: #0D77BD !important;
  margin-top: 30px !important;
  margin-left: 10px !important;
  border-radius: 20px !important;
}
.remove{
  font-size: 16px;
  color: #0D77BD;
  font-weight: 600;
  font-family: 'gilroyReg';
  margin-top: 40px;
  margin-left: 10px;
}
.propic{
  border-radius: 50%;
  width: 100px;
  height: 100px;
 
}
input,
input::placeholder {
    font: 14px;
    font-family: 'gilroyMed';
    color: #E6E6E6;
}
.login{
  padding-top: 270px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.welcome{
  color:  #0D2641;
  font-size: 30px;
  font-family: 'gilroyReg';
  font-weight: 600;
 
}
.studnt{
  border-radius: 50%;
}
.welcomePara{
  font-weight: 18px;
  font-family: 'ralewayReg';
  text-align: left;
}
.pasVisb{
  margin-left: -30px;
  margin-top: 10px;
}
.menu-flags{
  margin-top: 7px;
}
.Toggle>.react-switch-handle{
  top: 9px !important;
}