.Summery{
   margin-top: 15px;
    height: 160px;
    width: 23.5%;
    background: #F8F8F8;
    box-shadow: 0px 2px 20px -2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding-left: 15px;
   padding-right: 15px;
   
}
.summeyContent{
    display: flex;
    justify-content: space-between;
    padding: 25px 0px;
}
.summeyContent h5{
    color: #1B1B1B;
    font-family: 'gilroyMed';
    line-height: 21px;
    font-size: 18px;

}

.Number h6{
    color: #1B1B1B;
    font-size: 32px;
    line-height: 38px;
    font-family: 'gilroyBod';
   
}