.LoginBg{
    background-image: url(../../assets/images/loginbg.png);
    background-size: cover;
    background-position: center;
    min-height: 100vh;

}
.LayouBg{
    background-color: #ffffff;
    min-height: 100vh;
}