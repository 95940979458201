.ModalHeader{
    background-color: #0D2641 !important;
    border-radius: 4px 4px 0px 0px !important;
    color: #FFFFFF !important;
   
    font-family: 'gilroyMed';

}
.ModalCreateHeader{
    background-color: #EDEDED !important;
    border-radius: 4px 4px 0px 0px !important;
    color: #0D2641 !important;
   
    font-family: 'gilroyMed';

}
.title{
    color: #000000;
    font-size: 68px;
    font-family: 'gilroyMed';
    font-weight: 600;
}