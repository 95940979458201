.Header{
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}
.Calender{
    display: inline-block;
   align-items: center;
    padding-left: 10px;
}
.Date{
    color: #000000;
    font-size: 18px;
    font-family: 'gilroyReg';
    font-weight: 300;
     line-height: 18.78px;
    
}
.Logo{
    text-align: center;
    color: #868686;
    font-size: 26px;
    font-weight: 700;
    margin: 50px 16px;
}
.Searchprof{
    display: inline-flex;
}
.profile{
    text-indent: 150px !important;
}


.Search{
    padding-right: 25px;
}
.ProfileDescrp{
    display: inline-flex;
    align-items: center;
    margin-top: -15px !important;
}
.profName{
    font-size: 14px;
    color:  #000000;
    font-family: 'ralewayReg';
    font-weight: 600;
    padding-left: 5px;
    padding-right: 5px;
}