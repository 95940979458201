.LiveCard{
    margin-top: 15px;
    height: 260px;
    width: 23.5%;
    background: #F8F8F8;
    box-shadow: 0px 2px 20px -2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}
.TopHead{
    height: 50px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color:  #0D2641;;
}
.trackHead{
    display: flex;
    justify-content: space-between;
    padding: 15px;
   
}
.trackHead h3{
    font-size: 14px;
    font-family: 'gilroyMed';
    line-height: 16px;
    color:#FFFFFF;
}
.trackHead h5{
    font-size: 14px;
    font-family: 'gilroyMed';
    line-height: 16px;
    color:#75D1EA;
}
.trackBox{
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid #E9E9E9;
}
.TrackDet{
    font-size: 14px;
    color: #000000;
    font-family: 'gilroyMed';
    line-height: 16px;
    font-weight: 600;
}
.TrackDet span{
    opacity: 0.6;
    font-weight: 300 !important;
}
.Status{
   text-align: center;
   padding-top: 20px;
   color: #37BE5C;
   font-size: 12px;
   line-height: 14.66px;
   font-family: 'gilroyMed';
}
.Count{
    font-size: 18px;
    color:#000000;
    line-height: 21px;
    font-family: 'gilroyBod';
    
}
.LiveBusCard{
    margin-top: 15px;
    height: 260px;
    width: 100%;
    background: #F8F8F8;
    box-shadow: 0px 2px 20px -2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}
.busHead{
    display: flex;
}
.busHead h3{
    color: #FFFFFF;
    font-size: 14px;
    font-family: 'gilroyMed';
    color: 16.41px;
    padding-top: 15px;
    padding-right: 15px;
    padding-left: 15px;

}
.border{
    border: 1px solid #FFFFFF;
    margin-top: 10px;
}
.Location{
    color: #BABABA;
    font-size: 12px;
    font-family: 'gilroyMed';
    line-height: 14.06px;
    padding-top: 15px;
}
.locationName{
    color: #1B1B1B;
    font-size: 16px;
    line-height: 18.74px;
}
.Route{
    color: #1B1B1B;
    font-size: 16px;
    line-height: 18.74px;

}